/* eslint-disable */
import api from "@/api/api";
import { checkError } from "../utils/checkError";
import { sendFile } from '../utils/sendFile'
import moment from "moment";

const submit = async (template, planilha, error, table) => {
  const id_file = await sendFile(table);
  const submitPlan = planilha.data.map((row) => {
    let data_pre = "";

    if (row["0.2"].value) {
      data_pre = moment(row["0.2"].value, "DD/MM/YYYY").toDate();

      data_pre
        ? data_pre.setTime(
          data_pre.getTime() + data_pre.getTimezoneOffset() * 60 * 1000
        )
        : "";
    }

    return {
      cod_centro: row["0.0"].value || null,
      fazenda_produtor_id: row["0.1"].value || null,
      data_coleta: data_pre || "",
      hora_coleta: row["0.3"].value || null,
      volume_01: row["0.4"].value ? row["0.4"].value.replaceAll('.', '').replaceAll(',', '.') : 0,
      volume_02: row["0.5"].value ? row["0.5"].value.replaceAll('.', '').replaceAll(',', '.') : 0,
      volume_03: row["0.6"].value ? row["0.6"].value.replaceAll('.', '').replaceAll(',', '.') : 0,
      volume_04: row["0.7"].value ? row["0.7"].value.replaceAll('.', '').replaceAll(',', '.') : 0,
      volume_05: row["0.8"].value ? row["0.8"].value.replaceAll('.', '').replaceAll(',', '.') : 0,
      volume_06: row["0.9"].value ? row["0.9"].value.replaceAll('.', '').replaceAll(',', '.') : 0,
      volume_07: row["0.10"].value ? row["0.10"].value.replaceAll('.', '').replaceAll(',', '.') : 0,
      volume_08: row["0.11"].value ? row["0.11"].value.replaceAll('.', '').replaceAll(',', '.') : 0,
      volume_09: row["0.12"].value ? row["0.12"].value.replaceAll('.', '').replaceAll(',', '.') : 0,
      volume_10: row["0.13"].value ? row["0.13"].value.replaceAll('.', '').replaceAll(',', '.') : 0,
      volume_11: row["0.14"].value ? row["0.14"].value.replaceAll('.', '').replaceAll(',', '.') : 0,
      volume_12: row["0.15"].value ? row["0.15"].value.replaceAll('.', '').replaceAll(',', '.') : 0,
      volume_13: row["0.16"].value ? row["0.16"].value.replaceAll('.', '').replaceAll(',', '.') : 0,
      volume_14: row["0.17"].value ? row["0.17"].value.replaceAll('.', '').replaceAll(',', '.') : 0,
      volume_15: row["0.18"].value ? row["0.18"].value.replaceAll('.', '').replaceAll(',', '.') : 0,
      volume_16: row["0.19"].value ? row["0.19"].value.replaceAll('.', '').replaceAll(',', '.') : 0,
      volume_17: row["0.20"].value ? row["0.20"].value.replaceAll('.', '').replaceAll(',', '.') : 0,
      volume_18: row["0.21"].value ? row["0.21"].value.replaceAll('.', '').replaceAll(',', '.') : 0,
      volume_19: row["0.22"].value ? row["0.22"].value.replaceAll('.', '').replaceAll(',', '.') : 0,
      volume_20: row["0.23"].value ? row["0.23"].value.replaceAll('.', '').replaceAll(',', '.') : 0,
      volume_21: row["0.24"].value ? row["0.24"].value.replaceAll('.', '').replaceAll(',', '.') : 0,
      volume_22: row["0.25"].value ? row["0.25"].value.replaceAll('.', '').replaceAll(',', '.') : 0,
      volume_23: row["0.26"].value ? row["0.26"].value.replaceAll('.', '').replaceAll(',', '.') : 0,
      volume_24: row["0.27"].value ? row["0.27"].value.replaceAll('.', '').replaceAll(',', '.') : 0,
      volume_25: row["0.28"].value ? row["0.28"].value.replaceAll('.', '').replaceAll(',', '.') : 0,
      volume_26: row["0.29"].value ? row["0.29"].value.replaceAll('.', '').replaceAll(',', '.') : 0,
      volume_27: row["0.30"].value ? row["0.30"].value.replaceAll('.', '').replaceAll(',', '.') : 0,
      volume_28: row["0.31"].value ? row["0.31"].value.replaceAll('.', '').replaceAll(',', '.') : 0,
      volume_29: row["0.32"].value ? row["0.32"].value.replaceAll('.', '').replaceAll(',', '.') : 0,
      volume_30: row["0.33"].value ? row["0.33"].value.replaceAll('.', '').replaceAll(',', '.') : 0,
      volume_31: row["0.34"].value ? row["0.34"].value.replaceAll('.', '').replaceAll(',', '.') : 0,
      temperatura: row["0.35"].value ? row["0.35"].value.replace(",", ".") : 0,
      rota: row["0.36"].value,
    };
  });

  let envioPlan = []
  let retorno = []
  let nContador = 0
  let fazendas = 0
  let volume = 0

  for (let i = 0, len = submitPlan.length; i < len; i++) {

    envioPlan.push(submitPlan[i])

    ++nContador

    if (nContador >= 350 || (i + 1) >= len) {

      try {
        let response = await api.cadastrarEntidade(
          { volumes: envioPlan, erro: error, id_file: id_file },
          "volume/batch"
        )
        fazendas += response[0].fazendas
        volume += response[0].volume
      }
      catch (e) {
        retorno = [...retorno, ...e.errors]
        fazendas += e.fazendas
        volume += e.volume
      }
      envioPlan = []
      nContador = 0
    }
  }

  envioLog(retorno.length, fazendas, volume, id_file)

  return [retorno]
}

const formatData = (data) => {
  const data_formatada = [];
  data.forEach((row) =>
    data_formatada.push({
      "0.0": {
        value: row.cod_centro,
        ...checkError(row, "cod_centro"),
      },
      "0.1": {
        value: row.fazenda_produtor_id,
        ...checkError(row, "fazenda_produtor_id"),
      },
      "0.2": { value: row.data_coleta, ...checkError(row, "data_coleta") },
      "0.3": { value: row.hora_coleta, ...checkError(row, "hora_coleta") },
      "0.4": { value: row.volume_01, ...checkError(row, "volume_01") },
      "0.5": { value: row.volume_02, ...checkError(row, "volume_02") },
      "0.6": { value: row.volume_03, ...checkError(row, "volume_03") },
      "0.7": { value: row.volume_04, ...checkError(row, "volume_04") },
      "0.8": { value: row.volume_05, ...checkError(row, "volume_05") },
      "0.9": { value: row.volume_06, ...checkError(row, "volume_06") },
      "0.10": { value: row.volume_07, ...checkError(row, "volume_07") },
      "0.11": { value: row.volume_08, ...checkError(row, "volume_08") },
      "0.12": { value: row.volume_09, ...checkError(row, "volume_09") },
      "0.13": { value: row.volume_10, ...checkError(row, "volume_10") },
      "0.14": { value: row.volume_11, ...checkError(row, "volume_11") },
      "0.15": { value: row.volume_12, ...checkError(row, "volume_12") },
      "0.16": { value: row.volume_13, ...checkError(row, "volume_13") },
      "0.17": { value: row.volume_14, ...checkError(row, "volume_14") },
      "0.18": { value: row.volume_15, ...checkError(row, "volume_15") },
      "0.19": { value: row.volume_16, ...checkError(row, "volume_16") },
      "0.20": { value: row.volume_17, ...checkError(row, "volume_17") },
      "0.21": { value: row.volume_18, ...checkError(row, "volume_18") },
      "0.22": { value: row.volume_19, ...checkError(row, "volume_19") },
      "0.23": { value: row.volume_20, ...checkError(row, "volume_20") },
      "0.24": { value: row.volume_21, ...checkError(row, "volume_21") },
      "0.25": { value: row.volume_22, ...checkError(row, "volume_22") },
      "0.26": { value: row.volume_23, ...checkError(row, "volume_23") },
      "0.27": { value: row.volume_24, ...checkError(row, "volume_24") },
      "0.28": { value: row.volume_25, ...checkError(row, "volume_25") },
      "0.29": { value: row.volume_26, ...checkError(row, "volume_26") },
      "0.30": { value: row.volume_27, ...checkError(row, "volume_27") },
      "0.31": { value: row.volume_28, ...checkError(row, "volume_28") },
      "0.32": { value: row.volume_29, ...checkError(row, "volume_29") },
      "0.33": { value: row.volume_30, ...checkError(row, "volume_30") },
      "0.34": { value: row.volume_31, ...checkError(row, "volume_31") },
      "0.35": { value: row.temperatura, ...checkError(row, "temperatura") },
      "0.36": { value: row.rota, ...checkError(row, "rota") },
    })
  );
  return data_formatada;
};

async function envioLog (erros, fazendas, volume, id_file) {
  const log = `Fazendas ${fazendas} Volume ${volume}, Erros ${erros}`
  api.cadastrarEntidade({ log: log, tipo_integracao: 6, id_file: id_file }, 'integracaologs').then()
}

export default {
  submit,
  formatData,
};
