/* eslint-disable */
import { v4 } from 'uuid'
import api from '@/api/api'
import { sendFile } from '../utils/sendFile'
import { checkError } from '../utils/checkError'

const submit = async (template, planilha, error, table) => {
  const id_file = await sendFile(table)
  let submitPlanFinal = []
  const submitPlan = planilha.data.map(row => {
    row['0.0'].value = row['0.0'].value ? row['0.0'].value : '';
    const unis = row['0.0'].value.toString().normalize().replace(/\s+/g, '').split('-', -1)
    const unis2 = unis ? unis.map(uni => parseInt(uni)) : null
    const sexo = row['0.4'].value ? row['0.4'].value.normalize().toLowerCase().trim() : ''
    return {
      id: v4(),
      nome: row['0.1'].value || '',
      cpf: row['0.2'].value || null,
      rg: row['0.3'].value || null,
      sexo: ((sexo === 'm' || sexo === 'masculino' || sexo === 'masc') ? 'Masculino' : (sexo === 'f' || sexo === 'feminino' || sexo === 'fem') ? 'Feminino' : ''),
      telefones: [row['0.5'].value, ((row['0.6'].value === '') ? null : row['0.6'].value)],
      email: row['0.7'].value || null,
      data_nascimento: '',
      cods_centros: unis2 ? unis2 : '',
      cod_centro: row['0.0'].value,
      supervisor: row['0.8'].value
    }
  })
  submitPlanFinal = submitPlan
  return await api.cadastrarEntidade({ tecnicos: submitPlanFinal, erro: error, id_file: id_file }, 'tecnico/batch')
}

const formatData = (data) => {
  let data_formatada = []
  data.forEach(row => data_formatada.push({
    '0.0': { value: row.cod_centro, ...checkError(row, 'cod_centro') },
    '0.1': { value: row.nome, ...checkError(row, 'nome') },
    '0.2': { value: row.cpf, ...checkError(row, 'cpf') },
    '0.3': { value: row.rg, ...checkError(row, 'rg') },
    '0.4': { value: (row.sexo === 'Masculino' ? 'M' : (row.sexo === 'Feminino' ? 'F' : '')), ...checkError(row, 'sexo') },
    '0.5': { value: row.telefones[0], ...checkError(row, 'telefones') },
    '0.6': { value: row.telefones[1] },
    '0.7': { value: row.email, ...checkError(row, 'email') },
    '0.8': { value: row.supervisor, ...checkError(row, 'supervisor') },
  }))
  return data_formatada
}

export default {
  submit,
  formatData,
}
