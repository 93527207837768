export class WorksheetTemplate {
  constructor (data) {
    this.data = data

    this.fields = Object.entries(this.data).reduce(
      (acc, [group, fields]) =>
        Object.assign(acc, Object.entries(fields)
          .filter(entry => entry[0] !== 'meta')
          .reduce((accin, [key, field]) =>
            Object.assign(accin, {
              [`${group}.${key}`]:
                {
                  text: field.name,
                  ...field,
                  // slot: `${group}.${key}`,
                },
              },
            )
          , {}),
        )
    , {})
  }

  get listFields () {
    return Object.entries(this.fields).map(([key, fields]) => ({
      value: key,
      ...fields,
    }))
  }

  get headers () {
    return Object.entries(this.data).reduce(
      (acc, [group, fields]) => {
          return [...acc, ...Object.entries(fields)
            .filter(entry => entry[0] !== 'meta')
            .map(([key, field]) => ({
              value: `${group}.${key}`,
              text: field.name,
              map: field.map,
              align: field && field.type && ['numero', 'currency'].includes(field.type) ? 'right' : 'left',
              width: '600px',
            }))]
        }
      , [])
  }

  field (map) {
    return Object.values(this.fields).find(field => field.map === map)
  }
}
