<template>
  <v-dialog
    v-model="dialog"
    max-width="1320px"
    max-height="1200px"
    width="1200px"
    scrollable
    persistent
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        rounded
        class="white--text my-1 text-none"
        color="primary"
        small
        v-bind="$attrs"
        @click="getDados"
        v-on="on"
      >
        <v-icon
          dark
          left
        >
          mdi-history
        </v-icon>
        Histórico
      </v-btn>
    </template>
    <v-card>
      <v-toolbar
        color="primary"
        dark
      >
        <v-col>
          <v-toolbar-title>
            Histórico de importações
          </v-toolbar-title>
        </v-col>
        <v-btn
          icon
          dark
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <base-loading v-if="loading" />
      <div
        v-else
      >
        <v-card-text>
          <crud-list
            v-model="logs"
            :headers="headers"
            :item-height="$vuetify.breakpoint.xl ? '600' : '500'"
            :slots="[
              'item.data'
            ]"
            :sort-by="['data']"
            :sort-desc="[true]"
            :actions="actions"
          >
            <template v-slot:[`item.data`]="{ item }">
              <span>{{ moment( item.data ).format("DD/MM/YYYY HH:mm") }}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                v-if="item.id_file"
                icon
                small
                @click="dowloadTable(item)"
                v-on="on"
              >
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </template>
          </crud-list>
        </v-card-text>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
  import { mapState, mapActions, mapMutations } from 'vuex'
  import { BOOTSTRAP, CLOSE, OPEN, DOWLOAD } from '../../store/modules/integracoes'
  import moment from 'moment'

  export default {
    props: {
      templateId: {
        type: Number,
        default: 1,
      },
    },
    data () {
      return {
        dialog: false,
        moment,
        actions: [
          {
            title: 'Baixar planilha utilizada nesta importação.',
            icon: 'mdi-download',
            color: 'primary',
            click: (item) => this.dowloadTable(item),
          },
        ],
        headers: [
          { text: '', sortable: false, groupable: false, floatingfilter: false, value: 'actions', width: '50' },
          { align: 'left', sortable: true, text: 'Usuário', value: 'user_name', width: 'auto', filterable: true },
          { align: 'left', sortable: true, text: 'Data', value: 'data', width: 'auto' },
          { align: 'left', sortable: true, text: 'Observação', value: 'log', width: 'auto' },
        ],
      }
    },
    computed: {
      ...mapState('integracoes', ['loading', 'logs']),
    },
    methods: {
      ...mapActions('integracoes', [BOOTSTRAP, DOWLOAD]),
      ...mapMutations('integracoes', [CLOSE, OPEN]),
      async getDados () {
        await this.BOOTSTRAP({ id: this.templateId })
      },
      nome (tipo) {
        switch (tipo) {
          case 4:
            return 'Unidade_Negocio'
          case 3:
            return 'Tecnicos'
          case 5:
            return 'Fornecedor'
          case 1:
            return 'Produtor'
          case 2:
            return 'Produto'
          case 6:
            return 'Leite_diario'
          case 9:
            return 'Leite_não_conforme'
          case 7:
            return 'Qualidade_leite'
          case 8:
            return 'Catalogo_Preco'
          default:
            return 'Tabela'
        }
      },
      dowloadTable (data) {
        if (data.id_file) {
          this.DOWLOAD({ id: data.id_file, name: this.nome(data.tipo_integracao) })
        }
      },
    },
  }
</script>
