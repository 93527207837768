/* eslint-disable */
import api from '@/api/api'
import { validarCNPJ } from '@/utils/validacoes'
import { v4 } from 'uuid'
import { checkError } from '../utils/checkError'
import { sendFile } from '../utils/sendFile'

const submit = async (template, planilha, error, table) => {
  const id_file = await sendFile(table)
  const submitPlan = planilha.data.map(row => {
    const isPj = validarCNPJ(row['0.5'].value)
    const inscricao_estadual_antiga = (isPj) ? row['0.4'].value : null
    return {
      id: v4(),
      cod_centro: row['0.0'].value || null,
      pessoaFis: !(isPj),
      cpf: (isPj) ? null : row['0.3'].value || null,
      cnpj: (isPj) ? row['0.5'].value : null,
      nome: (isPj) ? '' : row['0.4'].value,
      razao_social: (isPj) ? row['0.6'].value : null,
      nome_fantasia: (isPj) ? row['0.6'].value : null,
      inscricao_estadual: row['0.7'].value ? ((isPj) ? (!isNaN(row['0.7'].value.replace(/\D/g, '')) ? row['0.7'].value.replace(/\D/g, '') : '') : null) : '',
      inscricao_estadual_antiga,
      telefones: [row['0.8'].value, ((row['0.9'].value === '') ? null : row['0.9'].value)],
      cidade: row['0.10'].value || null,
      estado: row['0.11'].value || null,
      pais: row['0.12'].value || null,
      logradouro: row['0.13'].value || null,
      bairro: row['0.14'].value || null,
      numero: row['0.15'].value || null,
      complemento: row['0.16'].value || null,
      cep: row['0.17'].value || null,
      email: null,
      data_nascimento: '',
      sexo: '',
      rg: row['0.7'].value ? ((isPj) ? '' : row['0.7'].value.replace(/[a-z]/gi, '')) : '',
      banco: row['0.20'].value || null,
      agencia: row['0.21'].value || null,
      conta: row['0.22'].value || null,
      cadastro: {
        produtor_id: '',
        cod_produtor_fornecedor: row['0.2'].value || null,
        cod_produtor_cliente: row['0.1'].value || null,
        ativo: row['0.18'].value && row['0.18'].value?.toLowerCase() === 'inativo' ? 0 : 1,
        tecnico: row['0.19'].value || null,
      },
      fazenda: {
        razao_social: (isPj) ? row['0.6'].value : 'FAZENDA DE ' + row['0.4'].value,
        nome_fantasia: (isPj) ? row['0.6'].value : null,
        ramo_atividade: '',
        nirf: '',
        cnpj: (isPj) ? row['0.5'].value : '',
        inscricao_estadual: row['1.0'].value ? ((isPj) ? (!isNaN(row['1.0'].value.replace(/\D/g, '')) ? row['1.0'].value.replace(/\D/g, '') : '') : null) : '',
        latitude: row['1.1'].value,
        longitude: row['1.2'].value,
        cidade: row['0.10'].value || null,
        estado: row['0.11'].value || null,
        pais: row['0.12'].value || null,
        bairro: row['0.14'].value || null,
        logradouro: row['0.13'].value || null,
        numero: row['0.15'].value || null,
        cep: row['0.17'].value || null,
        complemento: row['0.16'].value || null,
        telefones: [row['0.8'].value, ((row['0.9'].value === '') ? null : row['0.9'].value)],
      },
    }
  })


  let envioPlan = []
  let retorno = []
  let nContador = 0
  let insert = 0
  let update = 0

  for (let i = 0; i < submitPlan.length; i++) {

    envioPlan.push(submitPlan[i])

    ++nContador

    if (nContador >= 500 || (i + 1) >= submitPlan.length) {

      try {
        let response = await api.cadastrarEntidade({ produtores: envioPlan, erro: error }, 'produtor/batch')
        insert += response[0].insert
        update += response[0].update
      }
      catch (e) {
        retorno = [...retorno, ...e.errors]
        insert += e.insert
        update += e.update
      }
      envioPlan = []
      nContador = 0
    }
  }
  envioLog(retorno.length, insert, update, id_file)

  if (retorno.length) {
    throw retorno
  }

  return retorno
}

const formatData = (data) => {
  let data_formatada = []
  data.forEach(row => data_formatada.push({
    '0.0': { value: row.cod_centro, ...checkError(row, 'cod_centro') },
    '0.1': { value: row.cadastro.cod_produtor_cliente, ...checkError(row, 'cadastro.cod_produtor_cliente') },
    '0.2': { value: row.cadastro.cod_produtor_fornecedor, ...checkError(row, 'cadastro.cod_produtor_fornecedor') },
    '0.3': { value: row.cpf, ...checkError(row, 'cpf') },
    '0.4': { value: row.nome || row.razao_social, ...checkError(row, ['nome', 'razao_social']) },
    '0.5': { value: row.cnpj, ...checkError(row, 'cnpj') },
    '0.7': { value: row.rg, ...checkError(row, 'rg') },
    '0.8': { value: row.telefones[0], ...checkError(row, 'telefones') },
    '0.9': { value: row.telefones[1] },
    '0.10': { value: row.cidade, ...checkError(row, 'cidade') },
    '0.11': { value: row.estado, ...checkError(row, 'estado') },
    '0.12': { value: row.pais, ...checkError(row, 'pais') },
    '0.19': { value: row.cadastro.tecnico, ...checkError(row, 'cadastro.tecnico') },
    '0.20': { value: row.banco, ...checkError(row, 'banco') },
    '0.21': { value: row.agencia, ...checkError(row, 'agencia') },
    '0.22': { value: row.conta, ...checkError(row, 'conta') },
    '1.0': { value: row.inscricao_estadual_antiga, ...checkError(row, 'inscricao_estadual') },
    '1.1': { value: row.fazenda.latitude },
    '1.2': { value: row.fazenda.longitude },
  }))
  return data_formatada
}

async function envioLog (erros, insert, update, id_file) {
  const log = `Cadastrados ${insert}, Atualizados ${update}, Erros ${erros}`
  api.cadastrarEntidade({ log: log, tipo_integracao: 1, id_file: id_file }, 'integracaologs').then()
}

export default {
  submit,
  formatData,
}
