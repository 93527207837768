/* eslint-disable */
import { v4 } from 'uuid'
import api from '@/api/api'
import { UnidadeNegocio } from '@/models/UnidadeNegocio'
import { sendFile } from '../utils/sendFile'
import { checkError } from '../utils/checkError'

const submit = async (template, planilha, error, table) => {
  const id_file = await sendFile(table)
  const submitPlan = planilha.data.map(row => {
    return new UnidadeNegocio({
      id: v4(),
      cod_centro: row['0.0'].value || null,
      cod_clube: row['0.1'].value || null,
      razao_social: row['0.2'].value,
      nome_fantasia: row['0.3'].value,
      inscricao_estadual: '',
      cnpj: row['0.4'].value || null,
      telefones: [row['0.5'].value, ((row['0.6'].value === '') ? null : row['0.6'].value)],
      email: row['0.7'].value,
      email_adm_clube: row['0.8'].value,
      logradouro: row['0.9'].value,
      numero: row['0.10'].value,
      bairro: row['0.11'].value,
      complemento: row['0.12'].value,
      cidade: row['0.13'].value || null,
      estado: row['0.14'].value || null,
      cep: row['0.15'].value,
      pais: row['0.16'].value || null,
    })
  })
  return await api.cadastrarEntidade({ unidades: submitPlan, erro: error, id_file: id_file }, 'unidadeNegocio/batch')
}

const formatData = (data) => {
  let data_formatada = []
  data.forEach(row => data_formatada.push({
    '0.0': { value: row.cod_centro, ...checkError(row, 'cod_centro') },
    '0.1': { value: row.cod_clube, ...checkError(row, 'cod_clube') },
    '0.2': { value: row.razao_social, ...checkError(row, 'razao_social') },
    '0.3': { value: row.nome_fantasia, ...checkError(row, 'nome_fantasia') },
    '0.4': { value: row.cnpj, ...checkError(row, 'cnpj') },
    '0.5': { value: row.telefones[0], ...checkError(row, 'telefones') },
    '0.6': { value: row.telefones[1] },
    '0.7': { value: row.email, ...checkError(row, 'email') },
    '0.8': { value: row.email_adm_clube, ...checkError(row, 'email_adm_clube') },
    '0.9': { value: row.logradouro, ...checkError(row, 'logradouro') },
    '0.10': { value: row.numero, ...checkError(row, 'numero') },
    '0.11': { value: row.bairro, ...checkError(row, 'bairro') },
    '0.12': { value: row.complemento, ...checkError(row, 'complemento') },
    '0.13': { value: row.cidade, ...checkError(row, 'cidade') },
    '0.14': { value: row.estado, ...checkError(row, 'estado') },
    '0.15': { value: row.cep, ...checkError(row, 'cep') },
    '0.16': { value: row.pais, ...checkError(row, 'pais') },
  }))
  return data_formatada
}

export default {
  submit,
  formatData,
}
