<template>
  <v-container>
    <v-row>
      <v-col
        md="6"
        cols="12"
      >
        <material-card
          icon="mdi-account-reactivate"
          title="Integração de cadastro"
          color="#003677"
        >
          <v-col
            cols="12"
          >
            <v-simple-table
              height="400px"
            >
              <thead>
                <tr>
                  <th
                    width="35%"
                    class="text-left"
                  >
                    Integração
                  </th>
                  <th
                    class="text-center"
                  >
                    Ações
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Cadastro de unidade de negócio
                  </td>
                  <td
                    class="text-right"
                    style="padding: 10px;"
                  >
                    <planilha-upload-dialog
                      :disabled="!$store.getters.hasPermission('inserir_unidade_negocio')"
                      :template-id="4"
                      small
                      color="primary"
                    />
                    <v-btn
                      :disabled="!$store.getters.hasPermission('inserir_unidade_negocio')"
                      href="/Modelo-importacao/modelo-unidade-negocio.xlsx"
                      target="_blank"
                      class="white--text my-1 text-none"
                      color="primary"
                      small
                      title="Modelo da planilha"
                      rounded
                      style="margin: 2px;"
                    >
                      <v-icon
                        left
                      >
                        mdi-table
                      </v-icon>
                      <span class="mr-1">
                        Modelo
                      </span>
                    </v-btn>
                    <integracao-dialog-log
                      :template-id="4"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    Cadastro de técnicos
                  </td>
                  <td
                    class="text-right"
                    style="padding: 10px;"
                  >
                    <planilha-upload-dialog
                      :disabled="!$store.getters.hasPermission('inserir_tecnico')"
                      :template-id="3"
                      small
                      color="primary"
                    />
                    <v-btn
                      :disabled="!$store.getters.hasPermission('inserir_tecnico')"
                      href="/Modelo-importacao/modelo-tecnicos.xlsx"
                      target="_blank"
                      class="white--text text-none text-white"
                      color="primary"
                      small
                      title="Modelo da planilha"
                      rounded
                      style="margin: 2px;"
                    >
                      <v-icon
                        dark
                        left
                      >
                        mdi-table
                      </v-icon>
                      <span class="mr-1">
                        Modelo
                      </span>
                    </v-btn>
                    <integracao-dialog-log
                      :template-id="3"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    Cadastro de fornecedores
                  </td>
                  <td
                    class="text-right"
                    style="padding: 10px;"
                  >
                    <planilha-upload-dialog
                      :disabled="!$store.getters.hasPermission('inserir_fornecedor')"
                      :template-id="5"
                      small
                      color="primary"
                    />
                    <v-btn
                      :disabled="!$store.getters.hasPermission('inserir_fornecedor')"
                      href="/Modelo-importacao/modelo-fornecedores.xlsx"
                      target="_blank"
                      class="white--text my-1 text-none"
                      color="primary"
                      small
                      title="Modelo da planilha"
                      rounded
                      style="margin: 2px;"
                    >
                      <v-icon
                        dark
                        left
                      >
                        mdi-table
                      </v-icon>
                      <span class="mr-1">
                        Modelo
                      </span>
                    </v-btn>
                    <integracao-dialog-log
                      :template-id="5"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    Cadastro de produtores
                  </td>
                  <td
                    class="text-right"
                    style="padding: 10px;"
                  >
                    <planilha-upload-dialog
                      :disabled="!$store.getters.hasPermission('inserir_produtor')"
                      :template-id="1"
                      small
                      color="primary"
                    />
                    <v-btn
                      :disabled="!$store.getters.hasPermission('inserir_produtor')"
                      href="/Modelo-importacao/modelo-produtores.xlsx"
                      target="_blank"
                      class="white--text my-1 text-none"
                      color="primary"
                      small
                      title="Modelo da planilha"
                      rounded
                      style="margin: 2px;"
                    >
                      <v-icon
                        dark
                        left
                      >
                        mdi-table
                      </v-icon>
                      <span class="mr-1">
                        Modelo
                      </span>
                    </v-btn>
                    <integracao-dialog-log
                      :template-id="1"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    Cadastro de produtos
                  </td>
                  <td
                    class="text-right"
                    style="padding: 10px;"
                  >
                    <planilha-upload-dialog
                      :disabled="!$store.getters.hasPermission('inserir_produto')"
                      :template-id="2"
                      small
                      color="primary"
                    />
                    <v-btn
                      :disabled="!$store.getters.hasPermission('inserir_produto')"
                      href="/Modelo-importacao/modelo-produtos.xlsx"
                      target="_blank"
                      class="white--text my-1 text-none"
                      color="primary"
                      small
                      title="Modelo da planilha"
                      rounded
                      style="margin: 2px;"
                    >
                      <v-icon
                        dark
                        left
                      >
                        mdi-table
                      </v-icon>
                      <span class="mr-1">
                        Modelo
                      </span>
                    </v-btn>
                    <integracao-dialog-log
                      :template-id="2"
                    />
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </material-card>
      </v-col>
      <v-col
        md="6"
        cols="12"
      >
        <material-card
          icon="mdi-account-convert-outline"
          title="Integração das movimentações"
          color="#003677"
        >
          <v-col
            cols="12"
          >
            <v-simple-table
              height="400px"
            >
              <thead>
                <tr>
                  <th
                    class="text-left"
                    width="35%"
                  >
                    Integração
                  </th>
                  <th
                    class="text-center"
                  >
                    Ações
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Movimento do leite diário
                  </td>
                  <td
                    class="text-right"
                    style="padding: 10px;"
                  >
                    <planilha-upload-dialog
                      :disabled="!$store.getters.hasPermission('inserir_volume')"
                      :template-id="6"
                      small
                      color="primary"
                    />
                    <v-btn
                      :disabled="!$store.getters.hasPermission('inserir_volume')"
                      href="/Modelo-importacao/modelo-leite-diario.xlsx"
                      target="_blank"
                      class="white--text my-1 text-none"
                      color="primary"
                      small
                      title="Modelo da planilha"
                      rounded
                      style="margin: 2px;"
                    >
                      <v-icon
                        dark
                        left
                      >
                        mdi-table
                      </v-icon>
                      <span class="mr-1">
                        Modelo
                      </span>
                    </v-btn>
                    <integracao-dialog-log
                      :template-id="6"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    Movimento do leite não conforme
                  </td>
                  <td
                    class="text-right"
                    style="padding: 10px;"
                  >
                    <planilha-upload-dialog
                      :disabled="!$store.getters.hasPermission('inserir_volume')"
                      :template-id="9"
                      small
                      color="primary"
                    />
                    <v-btn
                      :disabled="!$store.getters.hasPermission('inserir_volume')"
                      href="/Modelo-importacao/modelo-leite-condenado.xlsx"
                      target="_blank"
                      class="white--text my-1 text-none"
                      color="primary"
                      small
                      title="Modelo da planilha"
                      rounded
                      style="margin: 2px;"
                    >
                      <v-icon
                        dark
                        left
                      >
                        mdi-table
                      </v-icon>
                      <span class="mr-1">
                        Modelo
                      </span>
                    </v-btn>
                    <integracao-dialog-log
                      :template-id="9"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    Movimento do leite mensal
                  </td>
                  <td
                    class="text-right"
                    style="padding: 10px;"
                  >
                    <planilha-upload-dialog
                      disabled
                      small
                      color="primary"
                    />
                    <v-btn
                      class="text-none"
                      disabled
                      color="primary"
                      small
                      title="Modelo da planilha"
                      rounded
                      style="margin: 2px;"
                    >
                      <v-icon
                        dark
                        left
                      >
                        mdi-table
                      </v-icon>
                      <span class="mr-1">
                        Modelo
                      </span>
                    </v-btn>
                    <integracao-dialog-log
                      disabled
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    Movimento da qualidade do leite
                  </td>
                  <td
                    class="text-right"
                    style="padding: 10px;"
                  >
                    <planilha-upload-dialog
                      :disabled="!$store.getters.hasPermission('inserir_qualidade')"
                      :template-id="7"
                      small
                      color="primary"
                    />
                    <v-btn
                      :disabled="!$store.getters.hasPermission('inserir_qualidade')"
                      href="/Modelo-importacao/modelo-analise-qualidade.xlsx"
                      target="_blank"
                      class="white--text my-1 text-none"
                      color="primary"
                      small
                      title="Modelo da planilha"
                      rounded
                      style="margin: 2px;"
                    >
                      <v-icon
                        dark
                        left
                      >
                        mdi-table
                      </v-icon>
                      <span class="mr-1">
                        Modelo
                      </span>
                    </v-btn>
                    <integracao-dialog-log
                      :template-id="7"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    Catálogo de preços
                  </td>
                  <td
                    class="text-right"
                    style="padding: 10px;"
                  >
                    <planilha-upload-dialog
                      :disabled="!$store.getters.hasPermission('inserir_item_catalogo')"
                      :template-id="8"
                      small
                      color="primary"
                    />
                    <v-btn
                      :disabled="!$store.getters.hasPermission('inserir_item_catalogo')"
                      href="/Modelo-importacao/modelo-catalogo-precos.xlsx"
                      target="_blank"
                      class="white--text my-1 text-none"
                      color="primary"
                      small
                      title="Modelo da planilha"
                      rounded
                      style="margin: 2px;"
                    >
                      <v-icon
                        dark
                        left
                      >
                        mdi-table
                      </v-icon>
                      <span class="mr-1">
                        Modelo
                      </span>
                    </v-btn>
                    <integracao-dialog-log
                      :template-id="8"
                    />
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </material-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import PlanilhaUploadDialog from '../../components/PlanillhaUpload'
  import MaterialCard from '../../components/base/MaterialCard'
  import IntegracaoDialogLog from './IntegracaoDialogLog.vue'

  export default {
    name: 'Integracao',
    components: {
      PlanilhaUploadDialog,
      MaterialCard,
      IntegracaoDialogLog,
    },
    data () {
      return {
      }
    },
  }
</script>
