/* eslint-disable */
import { v4 } from 'uuid'
import api from '@/api/api'
import { checkError } from '../utils/checkError'
import { sendFile } from '../utils/sendFile'

const submit = async (template, planilha, error, table) => {
  const id_file = await sendFile(table)
  const submitPlan = planilha.data.map(row => {
    return {
      id: v4(),
      cod_centro: row['0.0'].value || null,
      cod_prod: row['0.1'].value || null,
      preco: row["0.2"].value ? row['0.2'].value.replaceAll('.', '').replaceAll(',', '.') : 0,
      pedido_min: row["0.3"].value ? row['0.3'].value.replaceAll('.', '').replaceAll(',', '.') : 0,
      disponibilidade: row['0.4'].value?.toLowerCase().includes('imediata') ? 1 : row['0.4'].value?.toLowerCase().includes('demanda') ? 0 : 2,
      estoque: 0,
    }
  })
  return await api.cadastrarEntidade({ catalogo: submitPlan, erro: error, id_file: id_file }, 'catalogo/batch')
}

const formatData = (data) => {
  let data_formatada = []
  data.forEach(row => data_formatada.push({
    '0.0': { value: row.cod_centro, ...checkError(row, 'cod_centro') },
    '0.1': { value: row.cod_prod, ...checkError(row, 'cod_prod') },
    '0.2': { value: row.preco, ...checkError(row, 'preco') },
    '0.3': { value: row.pedido_min, ...checkError(row, 'pedido_min') },
    '0.4': { value: row.disponibilidade, ...checkError(row, 'disponibilidade') },
  }))
  return data_formatada
}

export default {
  submit,
  formatData,
}
