/* eslint-disable */
import api from "@/api/api";
import { checkError } from "../utils/checkError";
import moment from "moment";
import { sendFile } from '../utils/sendFile'
import { internalRemoveVirgula } from '../utils/formatToFloat'

const submit = async (template, planilha, error, table) => {
  const id_file = await sendFile(table);
  const submitPlan = planilha.data.map((row) => {
    let data_referencia_pre = "";
    let data_condenacao_pre = "";

    if (row["0.2"].value) {
      data_referencia_pre = moment(row["0.2"].value, "DD/MM/YYYY").toDate();

      data_referencia_pre
        ? data_referencia_pre.setTime(
          data_referencia_pre.getTime() + data_referencia_pre.getTimezoneOffset() * 60 * 1000
        )
        : "";
    }

    if (row["0.3"].value) {
      data_condenacao_pre = moment(row["0.3"].value, "DD/MM/YYYY").toDate();

      data_condenacao_pre
        ? data_condenacao_pre.setTime(
          data_condenacao_pre.getTime() + data_condenacao_pre.getTimezoneOffset() * 60 * 1000
        )
        : "";
    }

    return {
      cod_centro: row["0.0"].value || null,
      fazenda_produtor_id: row["0.1"].value || null,
      data_referencia: data_referencia_pre || "",
      data_condenacao: data_condenacao_pre || "",
      volume: row["0.4"].value ? row["0.4"].value.replaceAll('.', '').replaceAll(',', '.') : 0,
      codigo: row["0.5"].value || "",
      descricao_condenacao: row["0.6"].value || "",

    };
  });
  try{
    let envioPlan = []
    let retorno = []
    let nContador = 0
    let fazendas = 0
    let volume = 0


    for (let i = 0, total = submitPlan.length; i < total; i++) {

      envioPlan.push(submitPlan[i])

      ++nContador

      if (nContador >= 450 || (i + 1) >= total) {

        try {
          let response = await api.cadastrarEntidade(
            { volumes: envioPlan, erro: error, id_file: id_file },
            "volume-condenado/batch"
          )
          console.log(response)
          fazendas += response[0].fazendas
          volume += response[0].fazendas
        }
        catch (e) {
          retorno = [...retorno, ...e.errors]
          fazendas += e.fazendas
          volume += e.fazendas
        }
        envioPlan = []
        nContador = 0
      }
    }

    envioLog(retorno.length, fazendas, volume, id_file)

    if (retorno.length) {
      throw retorno
    }
    return [retorno]
  } catch (error) {
    return[error]
  }
};

const formatData = (data) => {
  const data_formatada = [];
  data.forEach((row) =>
    data_formatada.push({
      "0.0": {
        value: row.cod_centro,
        ...checkError(row, "cod_centro"),
      },
      "0.1": {
        value: row.fazenda_produtor_id,
        ...checkError(row, "fazenda_produtor_id"),
      },
      "0.2": {
        value: row.data_referencia
          ? moment(row.data_referencia).format("DD/MM/YYYY")
          : "",
        ...checkError(row, "data_referencia"),
      },
      "0.3": {
        value: moment(row.data_condenacao).format("DD/MM/YYYY"),
        ...checkError(row, "data_condenacao"),
      },
      "0.4": {
        value: row.volume,
        ...checkError(row, "volume"),
      },
      "0.5": {
        value: row.codigo,
        ...checkError(row, "codigo"),
      },
      "0.6": {
        value: row.descricao_condenacao,
        ...checkError(row, "descricao_condenacao"),
      },

    })
  );
  return data_formatada;
};

async function envioLog (erros, fazendas, volume, id_file) {
  const log = `Volumes não conforme ${volume} Fazendas ${fazendas}, Erros ${erros}`
  api.cadastrarEntidade({ log: log, tipo_integracao: 9, id_file: id_file }, 'integracaologs').then()
}

export default {
  submit,
  formatData,
};
