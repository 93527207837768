/* eslint-disable */
import { v4 } from 'uuid'
import api from '@/api/api'
import { sendFile } from '../utils/sendFile'
import { checkError } from '../utils/checkError'

const submit = async (template, planilha, error, table) => {
  const id_file = await sendFile(table)
  const submitPlan = planilha.data.map(row => {
    return {
      id: v4(),
      codigo: row['0.0'].value || null,
      nome: row['0.1'].value || '',
      fornecedor: row['0.2'].value || null,
      marca: row['0.3'].value || null,
      categoria: row['0.4'].value || null,
      unidade: row['0.5'].value || null,
      volume: row["0.6"].value ? row["0.6"].value.replaceAll('.', '').replaceAll(',', '.') : 0,
      descricao: row['0.7'].value || null,
      imagens: [],
      preco_venda_sugerido: '',
      update_rotulo: !!((row['0.8'].value || row['0.9'].value || row['0.10'].value || row['0.11'].value || row['0.12'].value || row['0.13'].value)),
      rotulo: JSON.stringify({
        consumo: row['0.8'].value,
        modo_uso: row['0.9'].value,
        validade: row['0.10'].value,
        indicacao: row['0.11'].value,
        restricoes: row['0.12'].value,
        apresentacao: row['0.13'].value,
      }),
    }
  })
  return await api.cadastrarEntidade({ produtos: submitPlan, erro: error, id_file: id_file }, 'produto/batch')
}

const formatData = (data) => {
  let data_formatada = []
  data.forEach(row => data_formatada.push({
    '0.0': { value: row.codigo, ...checkError(row, 'codigo') },
    '0.1': { value: row.nome, ...checkError(row, 'nome') },
    '0.2': { value: row.fornecedor, ...checkError(row, 'fornecedor') },
    '0.3': { value: row.marca, ...checkError(row, 'marca') },
    '0.4': { value: row.categoria, ...checkError(row, 'categoria') },
    '0.5': { value: row.unidade, ...checkError(row, 'unidade') },
    '0.6': { value: row.volume, ...checkError(row, 'volume') },
    '0.7': { value: row.descricao, ...checkError(row, 'descricao') },
    '0.8': { value: row.consumo, ...checkError(row, 'consumo') },
    '0.9': { value: row.modo_uso, ...checkError(row, 'modo_uso') },
    '0.10': { value: row.validade, ...checkError(row, 'validade') },
    '0.11': { value: row.indicacao, ...checkError(row, 'indicacao') },
    '0.12': { value: row.restricoes, ...checkError(row, 'restricoes') },
    '0.13': { value: row.apresentacao, ...checkError(row, 'apresentacao') },
  }))
  return data_formatada
}

export default {
  submit,
  formatData,
}
