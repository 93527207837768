import api from '@/api/api'
import { v4 } from 'uuid'

export const sendFile = async (file) => {
  let erro = false
  const id_file = v4()
  const formData = {
    id_file: id_file,
    path: 'xlsx/logs',
    xlsx: file,
  }

  try {
    await api.uploadFile(formData)
  } catch (error) {
    console.error(error)
    erro = true
  }
  if (erro) {
    try {
      await api.uploadFile(formData)
      erro = false
    } catch (error) {
      console.error(error)
    }
  }

  if (erro) {
    throw new Error('Erro ao enviar arquivo')
  }

  return id_file
}
