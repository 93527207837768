export default {
  telefone: {
    mask: ['(##) #########', '(##) #####-####'],
    rules: [['minLength', 10]],
  },
  cpf: {
    mask: ['###.###.###-##'],
    rules: ['cpf'],
  },
  cnpj: {
    mask: ['##.###.###/####-##'],
    rules: ['cnpj'],
  },
  cpf_cnpj: {
    mask: [],
    rules: ['cpf_cnpj'],
  },
  rg: {
    mask: ['##.###.###-#'],
    rules: [],
  },
  hora: {
    mask: ['##:##'],
    rules: [],
  },
  numero: {
    mask: [],
    rules: [],
  },
  currency: {
    mask: [],
    rules: [],
  },
  date: {
    mask: ['##/##/####'],
    rules: [],
  },
  boolean: {
    mask: [],
    rules: [],
  },
}
