<template>
  <v-dialog
    v-model="dialog"
    max-width="1320px"
    max-height="800px"
    width="unset"
    persistent
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        rounded
        color="primary"
        v-bind="$attrs"
        class="text-none text-white"
        :style="styleButton"
        v-on="on"
        @click="reset"
      >
        <v-icon
          dark
          left
        >
          mdi-application-import
        </v-icon>
        Importar
      </v-btn>
    </template>
    <v-card
      justify="center"
      align="center"
    >
      <v-toolbar
        color="primary"
        dark
      >
        <v-col
          cols="11.5"
        >
          <span class="headline">{{ dialogTitle }}</span>
        </v-col>
        <config-menu
          :columns="columns"
          :template="template"
          :template-id="templateId"
          @save="posConfig"
        />
        <v-btn
          icon
          dark
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container>
        <v-card-text>
          <div
            class="d-flex justify-end pb-2"
          >
            <v-col cols="12">
              <span>
                Após clicar em "Importar Planilha", a mesma será importada e os dados serão inseridos no sistema!<br>
                Se ocorrer alguma inconsistência, será possível editar os dados que apresentarem erros na tabela abaixo.
              </span>
            </v-col>
          </div>
          <v-card
            v-if="planilhaLoaded"
            outlined
            class="d-flex flex-column align-center justify-space-around"
          >
            <v-data-table
              :headers="headers"
              :items="planilha.data"
              :items-per-page="10"
              fixed-header
              :height="this.$vuetify.breakpoint.xl ? '500' : '230'"
            >
              <template>
                <thead>
                  <tr>
                    <th
                      v-for="item in headers"
                      :id="`head-${item.text}`"
                      :key="item.text"
                      class="pt-2"
                    >
                      {{ item.text }}
                      <v-select dense />
                    </th>
                  </tr>
                </thead>
              </template>
              <template
                v-for="(slot, index) in slots"
                v-slot:[slot]="{ value: cell }"
              >
                <v-edit-dialog
                  v-if="cell"
                  :key="slot"
                  :return-value.sync="cell.value"
                  @open="validateMapCell(headers[index].map,cell)"
                  @close="validateMapCell(headers[index].map,cell)"
                >
                  {{ cell ? cell.value : null }}
                  <v-tooltip
                    right
                    transition="fade-transition"
                    color="#F55253"
                  >
                    <template v-slot:activator="{on, attrs}">
                      <v-icon
                        v-show="cell.err"
                        color="error"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-alert-octagon
                      </v-icon>
                    </template>
                    <span class="font-weight-medium">{{ cell.msg && cell.msg.length ? cell.msg[0]: "* Inválido" }}</span>
                  </v-tooltip>
                  <template v-slot:input>
                    <v-text-field
                      v-model="cell.value"
                      label="Edit"
                      :error="cell.err"
                      :error-messages="errors"
                      single-line
                      counter
                    />
                  </template>
                </v-edit-dialog>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                  title="Excluí a linha"
                  small
                  color="red darken-3"
                  @click="handleDelete(item)"
                >
                  mdi-trash-can-outline
                </v-icon>
              </template>
            </v-data-table>
          </v-card>
          <base-file-drop
            v-else
            ref="file-drop"
            width="800px"
            accept=".xlsx"
            @files-selected="handleFiles"
          >
            <v-card
              outlined
              class="pb-10 pt-6 d-flex flex-column align-center justify-space-around"
              height="250px"
            >
              <v-btn
                icon
                color="primary"
                elevation="0"
                x-large
                @click="selectFiles"
              >
                <v-icon x-large>
                  mdi-file-upload
                </v-icon>
              </v-btn>

              <span> <b>Escolha</b> um arquivo do disco</span>
              <span> ou </span>
              <span> <b>arraste</b> um arquivo para cá </span>
            </v-card>
          </base-file-drop>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            class="text-none text-white mx-1"
            color="error"
            rounded
            @click="dialog = false"
          >
            <v-icon
              dark
              left
            >
              mdi-minus-circle
            </v-icon>
            Cancelar
          </v-btn>
          <div
            v-if="!loading && planilha != null"
          >
            <v-btn
              class="text-none nx-1 text-white mx-1"
              color="warning"
              rounded
              @click="clearTable()"
            >
              <v-icon>
                mdi-broom
              </v-icon>
              Limpar Tabela
            </v-btn>
          </div>
          <v-btn
            class="text-none text-white mx-1"
            :disabled="planilha === null"
            color="success"
            rounded
            @click="submit"
          >
            <v-icon
              dark
              left
            >
              mdi-checkbox-marked-circle
            </v-icon>
            Importar
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
  import formRules from '@/utils/formRules'
  import { validarCNPJ } from '@/utils/validacoes'
  import Swal from 'sweetalert2'
  import masker from 'vue-the-mask/src/masker'
  import tokens from 'vue-the-mask/src/tokens'
  import { Worksheet } from '../../utils/Worksheet'
  import { currencyFormatter } from '../../utils/formatter'
  import ConfigMenu from './ConfigMenu'
  import types from './Types'
  import { WorksheetTemplate } from './WorksheetTemplate'
  import templates from './templates'

  export default {
    components: {
      ConfigMenu,
    },
    props: {
      dialogTitle: {
        type: String,
        default: 'Upload de Arquivos',
      },
      templateId: {
        type: Number,
        default: 0,
      },
      styleButton: {
        type: String,
        default: 'margin: 2px',
      },
    },
    data () {
      return {
        dialog: false,
        hover: true,
        table: null,
        planilha: null,
        errors: null,
        template: { data: [] },
        loading: true,
        meta: null,
        file: null,
      }
    },
    computed: {
      planilhaLoaded () {
        return !!(this.planilha && this.headers)
      },
      headers () {
        const f = this.template ? this.template.headers : null
        if (f) { f.unshift({ text: '', value: 'actions', sortable: false, width: '50' }) }
        return f
      },
      slots () {
        return this.headers.map(h => `item.${h.value}`)
      },
      columns () {
        return this.planilha ? this.planilha.columns : []
      },
    },
    created () {
      this.loading = true
    },
    methods: {
      handleDelete (item) {
        const index = this.planilha.data.findIndex(r => r.id === item.id)
        this.planilha.data.splice(index, 1)
        if (this.planilha.data?.length === 0) { this.reset() }
      },
      async posConfig () {
        await this.getTemplate()
        this.planilha = new Worksheet(this.file, this.template)
        this.validateTable()
      },
      clearTable () {
        Swal.fire({
          title: 'Atenção',
          text: 'Tem certeza que deseja limpar a tabela?',
          icon: 'question',
          showCancelButton: true,
          showLoaderOnConfirm: true,
          confirmButtonColor: '#109010',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Limpar',
          cancelButtonText: 'Cancelar',
          reverseButtons: true,
          focusConfirm: false,
          preConfirm: () => {
            this.reset()
            return true
          },
        })
      },
      reset () {
        this.planilha = null
        this.template = { data: [] }
        this.$emit('update:template', this.template)
        this.getTemplate()
      },
      async getTemplate () {
        if (this.templateId > 0) {
          await this.api.getEntidade('configImportPlanilha', this.templateId).then(response => {
            this.meta = JSON.parse(response.data.config)['0'].meta
            this.template = new WorksheetTemplate(JSON.parse(response.data.config))
            this.$emit('update:template', this.template)
            this.loading = false
          })
        }
      },
      selectFiles () {
        this.$refs['file-drop'].activate()
      },
      handleFiles ($event) {
        Swal.fire({
          title: 'Aguarde... Lendo a planilha!',
        })
        Swal.showLoading()
        const reader = new FileReader($event[0])
        const readerBase64 = new FileReader($event[0])

        this.file = ''

        reader.onload = e => {
          try {
            this.file = e.target.result
            this.planilha = new Worksheet(e.target.result, this.template)
            this.validateTable()
          } finally {
            Swal.hideLoading()
            Swal.update({
              title: 'Planilha lida com sucesso!',
              icon: 'success',
            })
          }
        }
        readerBase64.onload = e => {
          this.table = e.target.result
        }

        reader.readAsArrayBuffer($event[0])
        readerBase64.readAsDataURL($event[0])
      },
      constructErrors (data) {
        this.errors = data.map(row => Object.fromEntries(Object.keys(row).map(key => ([key, false]))))
      },
      validateCell (field, cell, bypass = false) {
        if (field.required && !cell.value) {
          cell.err = true
          cell.msg = ['O campo deve ser preenchido.']
          return cell.msg[0]
        }
        if (!(field && field.type && cell)) {
          return { valid: true, msg: [] }
        }
        if (cell.err && cell.msg && !bypass) {
          return cell.msg[0]
        }

        let errors = []
        for (var type of field.type.split(',')) {
          const state = { valid: true, msg: [] }
          if (!types[type]) {
            continue
          }

          if (type === 'hora') {
            cell.value = cell.value?.length < 5 ? '0' + cell.value : cell.value
          }

          if (type === 'numero' && cell.value) {
            cell.value = (cell.value).replace(',', '.')
          }

          if (type === 'boolean') {
            cell.value = String(cell.value).toUpperCase() === 'X' || String(cell.value) === '1' || String(cell.value).toUpperCase() === 'SIM' ? 'Sim' : 'Não'
          }

          if (type === 'currency') {
            const cellStr = String(cell.value)
            const iPoint = cellStr.indexOf('.')
            const iComma = cellStr.indexOf(',')
            if (cell.value) {
              if (iPoint > iComma) {
                cell.value = currencyFormatter(cellStr.replaceAll(',', ''))
              } else {
                cell.value = currencyFormatter(cellStr.replaceAll('.', '').replaceAll(',', '.'))
              }
            }
          }

          if (type === 'date' && !cell.formatted) {
            cell.value = cell.value.split('.')
            if (cell.value?.length === 3) {
              [cell.value[0], cell.value[1]] = [cell.value[1], cell.value[0]]
            }
            if (cell.value?.length === 2) { cell.value.splice(1, 0, '01') }

            cell.value = new Date(cell.value)
            cell.value.setTime(cell.value.getTime() + cell.value.getTimezoneOffset() * 60 * 1000)
            cell.value = cell.value.toLocaleString('pt-BR', { year: 'numeric', month: 'numeric', day: 'numeric' })
            cell.formatted = true
          }

          if (type === 'telefone' || type === 'cpf' || type === 'cnpj' || type === 'cpf_cnpj') { cell.value = cell.value ? String(cell.value) : '' }

          if (type === 'cpf_cnpj') {
            type = (validarCNPJ(cell.value)) ? 'cnpj' : 'cpf'
          }

          const { rules = null, mask = null } = types[type]
          if (mask && !(Array.isArray(mask) && !mask.length)) {
            cell.value = masker(cell.value, mask, true, tokens)
          }

          const result = rules.reduce(({ valid, msg }, cur) => {
            if (!valid) {
              return { valid, msg }
            }

            const [rule, ...args] = Array.isArray(cur) ? cur : [cur]
            const res = args?.length ? formRules[rule](...args)(cell.value) : formRules[rules](cell.value)

            // IMPORTANTE: Nesse ponto, o retorno das funções de validação é tratado de acordo com o seu tipo,
            // que pode ser string (msg de erro) ou boolean
            return typeof res !== 'boolean' ? { valid: false, msg: [...msg, res] } : { valid: valid && !!res, msg }
          }, state)

          if (result.valid) {
            cell.msg = null
            cell.err = false
            return state
          } else errors = [...errors, ...result.msg]
        }

        cell.err = true
        cell.msg = errors
        return errors[0]
      },
      validateMapCell (map, cell) {
        const field = this.template.field(map)
        cell.err = false
        cell.msg = ['']
        const result = this.validateCell(field, cell, true)
        this.errors = result.msg
        return result
      },
      async submit () {
        if (this.planilha === null) {
          Swal.fire({
            title: 'Escolha um arquivo para prosseguir com a importação!',
            icon: 'warning',
            showConfirmButton: true,
          })
          return
        }

        const inserts = this.planilha.data?.length
        const imported = this.meta.field
        const importedText = this.meta.text
        Swal.fire({
          title: 'Importando Planilha!',
          text: `Serão importados ${inserts} registros de ${importedText}!`,
          icon: 'info',
          showCancelButton: true,
          showLoaderOnConfirm: true,
          confirmButtonColor: '#109010',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          reverseButtons: true,
          focusConfirm: false,
          preConfirm: () => {
            return templates[imported].submit(this.template, this.planilha, (this.errors !== null), this.table).then(result => {
              if (result?.length === 0 || result[0]?.length === 0) {
                this.dialog = false
                this.reset()
                Swal.fire(
                  'Atenção!',
                  'Os dados foram importados com sucesso!',
                  'success',
                )
                return
              }

              const newData = templates[imported].formatData(result[0])
              this.planilha.data = newData
              this.validateTable()
              Swal.fire(
                'Atenção!',
                'Alguns dados não foram importados, favor revisá-los e importá-los novamente.',
                'warning',
              )
            }).catch(error => {
              if (error && !error?.length) {
                console.error(error)
                Swal.hideLoading()
                Swal.fire({
                  icon: 'error',
                  title: 'Erro ao importar os dados!',
                  text: error.message,
                })
                return
              }
              const newData = templates[imported].formatData(error)
              this.planilha.data = newData
              this.validateTable()
              Swal.fire(
                'Atenção!',
                'Alguns dados não foram importados, favor revisá-los e importá-los novamente.',
                'warning',
              )
            })
          },
        })
      },

      validateTable () {
        const templateFields = this.template.fields
        this.planilha.data.forEach((row) => {
          Object.keys(templateFields).forEach(key => {
            this.validateCell(templateFields[key], row[key])
          })
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
 .v-data-table-header {
   th {
     max-width: 600px;
   }
 }
 tbody {
   td {
     max-width: 600px;
     word-wrap: break-word;
     white-space: normal;
   }
 }
</style>
