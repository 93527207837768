import produtor from './produtor'
import unidadenegocio from './unidadenegocio'
import tecnico from './tecnico'
import produto from './produto'
import fornecedor from './fornecedor'
import volume from './volume'
import qualidade from './qualidade'
import catalogo from './catalogo'
import volumeCondenado from './volumeCondenado'

export default {
  produtor,
  unidadenegocio,
  tecnico,
  produto,
  fornecedor,
  volume,
  qualidade,
  catalogo,
  volumeCondenado,
}
