<template>
  <v-menu
    left
    offset-x
    :close-on-content-click="false"
    max-width="400px"
    :value="shown"
  >
    <template #activator="{on,attrs}">
      <div style="height: 46px">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>
            mdi-table-cog
          </v-icon>
        </v-btn>
      </div>
    </template>
    <v-card
      max-height="500px"
      width="400px"
      style="margin-top: 0px;"
    >
      <v-form ref="form">
        <v-toolbar
          color="primary"
          dark
        >
          <v-card-title>
            Configurações da Planilha
          </v-card-title>
        </v-toolbar>
        <v-list
          v-for="(entValue, entName, index) in template.data"
          :key="entName"
          class="px-5"
        >
          <v-list-item
            v-for="([fieldName, fieldValue]) in Object.entries(entValue).filter(f => f[0] !== 'meta')"
            :key="`${entName}.${fieldName}`"
          >
            <v-row>
              <v-col>
                {{ fieldValue.name }}
                <v-autocomplete
                  v-if="columns.length"
                  v-model="fieldValue.map"
                  :items="columns"
                  :rules="[...(fieldValue.required ? [rules.required] : [])]"
                  dense
                  @change="$emit('update:template',template)"
                />
                <v-text-field
                  v-else
                  v-model="fieldValue.map"
                  :rules="[...(fieldValue.required ? [rules.required] : [])]"
                  dense
                  @change="$emit('update:template',template)"
                />
              </v-col>
            </v-row>
          </v-list-item>
          <v-divider v-if="index - Object.keys(template).length + 1" />
        </v-list>
        <v-card-actions
          class="justify-center"
          style="background-color: white;"
        >
          <v-btn
            class="text-none ms-4 text-white"
            color="success"
            lg
            rounded
            @click="saveConfig"
          >
            <v-icon
              dark
              left
            >
              mdi-checkbox-marked-circle
            </v-icon>
            Salvar
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-menu>
</template>
<script>
  import rules from '../../utils/formRules'
  import Swal from 'sweetalert2'

  export default {
    name: 'ConfigMenu',
    props: {
      columns: {
        type: Array,
        default: () => [],
      },
      template: {
        type: Object,
        default: () => { return { data: [] } },
      },
      templateId: {
        type: Number,
        default: 0,
      },
    },
    data () {
      return {
        rules,
        loading: true,
        shown: false,
      }
    },
    computed: {
    },
    methods: {
      async saveConfig () {
        if (this.templateId > 0) {
          this.shown = true
          Swal.fire({
            title: 'Gravando Configuração',
            text: 'Salvando a Configuração da Planilha!',
            timer: 2000,
          })
          Swal.showLoading()
          const form = {
            config: JSON.stringify(this.template.data),
          }
          await this.api.salvarEntidade(form, 'configImportPlanilha', this.templateId).then(response => {
            Swal.hideLoading()
            Swal.update({
              title: 'Gravando Configuração',
              icon: 'success',
              text: 'As configurações foram gravadas com sucesso!',
              didClose: () => {
                this.shown = false
              },
            })
          }).catch(err => {
            console.error(err)
            Swal.hideLoading()
            Swal.fire({
              icon: 'error',
              title: 'Atenção',
              text: 'Não foi possível salvar a Configuração da Planilha, Favor tentar novamente!',
              didClose: () => {
                this.shown = false
              },
            })
          })
        }
        this.$emit('save', null)
      },
    },
  }
</script>
