/* eslint-disable */
import api from '@/api/api'
import { sendFile } from '../utils/sendFile'
import { checkError } from '../utils/checkError'

const submit = async (template, planilha, error, table) => {
  const id_file = await sendFile(table)
  const datas_recalculo = new Set()
  const submitPlan = planilha.data.map(row => {
    const data_pre = row['0.6'].value ? new Date(row['0.6'].value.substring(3, 5) + '/' + row['0.6'].value.substring(0, 2) + '/' + row['0.6'].value.substring(6, 10)) : ''
    const data_ref = row['0.0'].value ? new Date(row['0.0'].value.substring(3, 5) + '/' + row['0.0'].value.substring(0, 2) + '/' + row['0.0'].value.substring(6, 10)) : ''
    let valor = row['0.5'].value ? row['0.5'].value.replaceAll('.', '').replaceAll(',', '.') : '0.000'
    if (data_ref) {
      datas_recalculo.add(data_ref.toISOString())
    }

    data_ref ? data_ref.setTime(data_ref.getTime() + data_ref.getTimezoneOffset() * 60 * 1000) : ''
    data_pre ? data_pre.setTime(data_pre.getTime() + data_pre.getTimezoneOffset() * 60 * 1000) : ''

    return {
      data_referencia: data_ref || '',
      codigo_coleta: row['0.1'].value || 0,
      fazenda_produtor_id: row['0.2'].value || null,
      sigla_analise: row['0.3'].value || null,
      status: row['0.4'].value || null,
      valor_analise: valor,
      data_analise: data_pre || '',
    }
  })

  let retorno = []
  const valoresQualidade = {}

  // Agrupar os registros por fazenda_produtor_id
  const groupedSubmitPlan = submitPlan.reduce((groups, item) => {
    const group = groups[item.fazenda_produtor_id] || [];
    group.push(item);

    const sigla = item.sigla_analise
    if (!valoresQualidade[sigla]) {
      valoresQualidade[sigla] = []
    }
    valoresQualidade[sigla].push(parseFloat(item.valor_analise))

    groups[item.fazenda_produtor_id] = group;
    return groups;
  }, {});

  let currentBatch = [];

  // Enviar os grupos em blocos de até 3000 registros
  for (const fazenda_produtor_id in groupedSubmitPlan) {
    const group = groupedSubmitPlan[fazenda_produtor_id];

    if (currentBatch.length + group.length > 3000) {
      // Enviar o lote atual se o grupo atual exceder o limite de 3000 registros
      try {
        retorno.concat(await api.cadastrarEntidade({ qualidades: currentBatch, erro: error }, 'qualidade/batch'));
      } catch (e) {
        console.log(e)
        break;
      }
      currentBatch = [];
    }

    // Adicionar o grupo atual ao lote
    currentBatch = currentBatch.concat(group);
  }

  // Enviar qualquer lote restante
  if (currentBatch.length > 0) {
    try {
      await api.cadastrarEntidade({ qualidades: currentBatch, erro: error }, 'qualidade/batch');
    } catch (e) {
      retorno = retorno.concat(e)
    }
  }

  enviarMedia(valoresQualidade, retorno, id_file)

  recalculoMedia([...datas_recalculo])
  return [retorno]
}

const formatData = (data) => {
  const data_formatada = []
  data.forEach(row => data_formatada.push({
    '0.0': { value: row.data_referencia, ...checkError(row, 'data_referencia') },
    '0.1': { value: row.codigo_coleta, ...checkError(row, 'codigo_coleta') },
    '0.2': { value: row.fazenda_produtor_id, ...checkError(row, 'fazenda_produtor_id') },
    '0.3': { value: row.sigla_analise, ...checkError(row, 'sigla_analise') },
    '0.4': { value: row.status, ...checkError(row, 'status') },
    '0.5': { value: row.valor_analise, ...checkError(row, 'valor_analise') },
    '0.6': { value: row.data_analise, ...checkError(row, 'data_analise') },
  }))
  return data_formatada
}

function enviarMedia (valorQali, error, id_file) {
  const media = {}

  Object.keys(valorQali).forEach((key) => {
    media[key] = valorQali[key].reduce((acc, curr) => acc + curr, 0)
  })

  error.forEach(err =>
    media[err.sigla_analise] -= err?.valor_analise
  )

  Object.keys(media).forEach((key) => {
    if (media[key] !== 0) {
      media[key] /= valorQali[key].length
      media[key] = media[key].toLocaleString('pt-BR', {
        maximumFractionDigits: 2
      })
    } else {
      delete media[key]
    }
  })
  let log = 'Nenhum dado inserido'

  if (Object.keys(media).length > 0) {
    log = 'Media de dados inseridos: '
    Object.keys(media).forEach((key) => {
      log += key + ' = ' + media[key] + '\n'
    })
  }
  log += `Erros = ${error.length}`

  api.cadastrarEntidade({ log: log, tipo_integracao: 7, id_file: id_file }, 'integracaologs').then()

}

async function recalculoMedia (datas) {
  if (datas.length > 0) {
    try {
      api.cadastrarEntidade({ data_referencia: datas }, 'mediasqualidade/recalcula')
    } catch (e) {
      console.error(e)
    }
  }
}

export default {
  submit,
  formatData,
}
